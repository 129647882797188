import { ComponentType, useEffect } from "react"

// Code override to add a copy button to <pre> blocks with hover scaling and include button styling
export function withCopyButton(Component: ComponentType): ComponentType {
    return (props) => {
        useEffect(() => {
            // Find all <pre> blocks in the rich text element
            const preBlocks = document.querySelectorAll("pre")

            preBlocks.forEach((block) => {
                // Create the copy button element
                const copyButton = document.createElement("button")
                copyButton.innerText = "Copy"
                copyButton.classList.add("copy-btn")

                // Style the button directly
                copyButton.style.position = "absolute"
                copyButton.style.top = "16px" // Top positioning
                copyButton.style.right = "16px" // Right positioning
                copyButton.style.padding = "5px 10px"
                copyButton.style.backgroundColor = "#4E85DF" // Button background color
                copyButton.style.color = "#fff"
                copyButton.style.border = "none"
                copyButton.style.borderRadius = "4px"
                copyButton.style.cursor = "pointer"
                copyButton.style.zIndex = "10" // Ensure it's above the content
                copyButton.style.fontFamily = "Inter, sans-serif" // Button font
                copyButton.style.transition = "transform 0.3s ease" // Smooth scaling

                // Copy the code block content when clicked
                copyButton.addEventListener("click", () => {
                    const codeContent = block.innerText
                    navigator.clipboard.writeText(codeContent).then(() => {
                        copyButton.innerText = "Copied!"
                        setTimeout(() => (copyButton.innerText = "Copy"), 2000)
                    })
                })

                // Ensure the button is not duplicated if it already exists
                if (!block.querySelector(".copy-btn")) {
                    // Add the copy button to the <pre> block
                    block.style.position = "relative" // Ensure positioning for the button
                    block.appendChild(copyButton)
                }

                // Hover effect to scale the button
                block.addEventListener("mouseenter", () => {
                    copyButton.style.transform = "scale(1.1)" // Scale button on hover
                })

                block.addEventListener("mouseleave", () => {
                    copyButton.style.transform = "scale(1)" // Return to normal size when not hovering
                })
            })
        }, [])

        return <Component {...props} />
    }
}
